export const standardDeliveryBox: string = `
  @media print {
    @page { margin: 0; }
  }
  html, body, #print-wrap, .v-card {
    margin: 0;
    height: 100%;
  }
  .box {
    box-sizing: border-box;
    padding: 12vh 6vw;
    break-before: page;
    height: 100%;
    font-size: 3vw;
    font-family: Arial, sans-serif;
    display: flex;
    flex-flow: column nowrap;
  }
  .box p {
    margin: 0;
  }
  .box-label-header,
  .box-label-content {
    padding: 1em;
  }
  .box-label-header,
  .box-label-footer,
  .box-label-content {
    box-sizing: border-box;
  }
  .box-label-header,
  .box-label-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .box-label-header {
    color: #000;
    text-transform: uppercase;
  }
  .box-label-content p {
    margin-bottom: 0.2em;
  }
  .customer-name,
  .box-count,
  .box-label-content {
    flex: 1 0 auto;
  }
  .box-label-footer {
    padding-top: 0.5em;
    border-top: 1px solid #ddd;
  }
`;

export const usFoodsDeliveryBox: string = `
  @media print {
    @page { margin: 0; }
  }
  html, body, #print-wrap, .v-card {
    margin: 0;
    height: 100%;
  }
  .box {
    box-sizing: border-box;
    padding: 12vh 6vw;
    break-before: page;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    font-size: 3vw;
    font-family: Arial, sans-serif;
  }
  .box p {
    margin: 0;
  }
  .product-details,
  .origin-details {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
  .product-details {
    flex: 1 0 auto;
    border-right: 1px solid #ccc;
    padding-right: 1em;
  }
  .origin-details {
    padding-left: 1em;
    text-align: center;
  }
  .product-details p {
    margin-bottom: 2px;
  }
  .product-details .label {
    margin-right: 4px;
    text-transform: uppercase;
    font-size: .9em;
  }
  .lot-details {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .lot-number {
    flex: 1 0 auto;
  }
  .logo img {
    width: 20vw;
  }
`;

export const ptiDeliveryBox: string = `
  @media print {
    @page { margin: 0; }
  }
  html, body, #print-wrap, .v-card {
    margin: 0;
    height: 100%;
  }
  .box {
    break-before: page;
    padding: 12vh 6vw;
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 3vw;
    font-family: Arial, sans-serif;
  }
  .box p {
    margin: 0;
  }
  .box,
  .box-label-content,
  .product,
  .product-details,
  .product-details .details {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .box,
  .product,
  .product-details .details {
    flex-flow: column nowrap;
  }
  .box-label-content,
  .product-details {
    flex-flow: row nowrap;
  }
  .product,
  .product .upc {
    flex: 1 0 auto;
  }
  .gs1-barcode,
  .upc,
  .harvest-details {
    text-align: center;
  }
  .gs1-barcode .vue-barcode-element {
    max-height: 30vh;
    max-width: 88vw;
  }
  .upc .vue-barcode-element {
    max-width: 36vw;
    vertical-align: bottom;
  }
  .product {
    max-width: 70vw;
    max-height: 66vh;
  }
  .product h1 {
    font-size: 1.3em;
    text-transform: uppercase;
    margin: 0 0 0.1em 0;
  }
  .product-details p {
    margin-bottom: 0.5em;
  }
  .product-details .weight {
    font-weight: bold;
  }
  .product-details .origin-country {
    font-size: 1.1em;
    font-weight: bold;
  }
  .harvest-details {
    min-width: 18vw;
  }
  .harvest-details h3 {
    font-weight: normal;
    font-size: 0.9em;
    margin: 0 0 0.2em 0;
  }
  .harvest-details .harvest-date {
    border: 1px solid #000;
    padding: 0.1em 0.2em;
    font-size: 1.2em;
  }
  .voice-pick {
    position: absolute;
    bottom: 12vh;
    right: 6vw;
    background-color: #000;
    color: #fff;
  }
  .voice-pick span {
    display: inline-block;
    margin: 0;
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
    line-height: 2;
    vertical-align: middle;
  }
  .voice-pick .vp-small {
    margin: 0 .6em;
  }
  .voice-pick .vp-large {
    font-size: 1.2em;
    margin-right: 0.5em;
  }
`;

export const giantEagleDeliveryBox: string = `
  @media print {
    @page { margin: 0; }
  }
  html, body, #print-wrap, .v-card {
    margin: 0;
    height: 100%;
  }
  .box {
    position: relative;
    display: block;
  }
  .box p,
  .box ul {
    margin: 0;
  }
  .box-label-content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 1.5em;
  }
  .eagle-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-right: 3em;
    padding-top: 1em;
    padding-left: 1.5em;
  }
  .lot-label{
    font-size:1.2em;
  }
  .origin-company {
    font-size: 1.2em;
    font-weight: bold;
  }
  .origin-address {
    font-size: .8em;
    white-space: nowrap;
  }
  .product-details {
    list-style: none;
    padding: 0;
  }
  .product {
    text-align: left;
    font-size: 1.2em;
    padding-top: 1em;
    padding-left: 1.3em;
    padding-right: 1.5em;
    font-weight: bold;
    display: block;
    white-space: nowrap;
  }
  .customer-po {
    font-size: 1.3em;
  }
`;

export const wholeFoodsDeliveryBox: string = `
  @media print {
    @page { margin: 0; }
  }
  html, body, #print-wrap, .v-card {
    margin: 0;
    height: 100%;
  }
  .box {
    box-sizing: border-box;
    padding: 12vh 6vw;
    break-before: page;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    font-size: 3vw;
    font-family: Arial, sans-serif;
    justify-content: space-between;
  }
  .box p,
  .box ul {
    margin: 0;
  }
  li {
    line-height: 1.4;
  }
  .box-label-content,
  .box-label-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .box-label-content {
    flex: 1 0 auto;
  }
  .box-label-footer {
    border-top: 1px solid #ccc;
    align-items: flex-end;
  }
  .box-label-footer p {
    margin-top: 0.2em;
  }
  .left-side,
  .right-side {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 0.5em;
    box-sizing: border-box;
  }
  .right-side {
    border-left: 1px solid #ccc;
    padding-left: 1em;
    flex: 1 0 auto;
    max-width: 50vw;
  }
  .left-side {
    padding-right: 1em;
    max-width: 38vw;
  }
  .origin-address {
    white-space: nowrap;
  }
  .origin-country {
    font-weight: bold;
  }
  .product-details {
    list-style: none;
    padding: 0;
  }
  .product-details strong {
    border: 1px solid #ccc;
    padding: 0 0.5em;
  }
  .product {
    text-align: center;
    padding-top: 0.5em;
  }
  .product .vue-barcode-element {
    max-width: 75%;
  }
  .product .name {
    font-size: 0.9em;
  }
  .customer-po {
    font-size: 1.1em;
    margin-bottom: 0.1em;
  }
`;

export const splitLotLabel: string = `
@media print {
  @page { margin: 0; }
}
html, body, #print-wrap, .v-card {
  margin: 0;
  height: 100%;
}
.box {
  box-sizing: border-box;
  padding: 12vh 6vw;
  break-before: page;
  height: 100%
  display: block;
  position: relative;
  font-size: 9pt;
  font-family: Arial, sans-serif;
}
.box p {
  margin: 0;
}
.box .product-name {
  margin-left: 5px;
  font-size: 1.3em;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 4px;
}
.box .lot-details {
  padding: 5px;
}

.barcode-wrap {
  text-align: left;
  padding-top: 0.1in;
}
.section {
  border-bottom: 1px solid;
  margin-bottom: 6vh;
  padding-bottom: 5px;
}
`;

export const inventoryLotLabel: string = `
  @media print {
    @page { margin: 0; }
  }
  html, body, #print-wrap, .v-card {
    margin: 0;
    height: 100%;
  }
  .box {
    box-sizing: border-box;
    padding: 12vh 6vw;
    break-before: page;
    height: 100%;
    display: block;
    position: relative;
    font-size: 9pt;
    font-family: Arial, sans-serif;
  }
  .box p {
    margin: 0;
  }
  .box .product-name {
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 4px;
  }
  .box .lot-details {
    padding-bottom: .2in;
    border-bottom: 1px solid #ccc;
  }
  .box .grade {
    font-size: 80px;
    float: right;
    padding-right: 60px;
    padding-top: -10px;
  }
  .barcode-wrap {
    text-align: center;
    padding-top: 0.1in;
  }
`;

export const inventoryClamshellLabel: string = `
  @media print {
    @page { margin: 0; }
  }
  html, body, #print-wrap, .v-card {
    margin: 0;
    height: 100%;
  }
  .noShow {
    display: none;
  }
  .box {
    box-sizing: border-box;
    padding: 7vh 12vw;
    break-before: page;
    font-size: 5vw;
    font-family: Arial, sans-serif;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    text-align: center;
  }
  .box p {
    margin: 0;
  }
  .logo img {
    max-width: 37vw;
    max-height: 20vh;
  }
  .origin-name {
    font-size: 1.4em;
  }
  .product,
  .product-details {
    margin-bottom: 1em;
  }
  .lot {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border: 1px solid #000;
    box-sizing: border-box;
  }
  .lot > * {
    padding: 0.3em 0.5em;
  }
  .lot .label {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    min-width: 30%;
  }
  .lot .lot-number {
    flex: 1 0 auto;
  }
  .extra {
    font-size: 1.1em;
  }
  .product .vue-barcode-element {
    max-width: 80%;
  }
  .product, .origin {
    height: 100% !important;
  }
  .origin {
    margin-bottom: 5%;
  }
  
`;

// .product .vue-barcode-element {
//   max-width: 80%;
// }

export const iteratorPrintTable: string = `
  @media print {
    @page { margin: 0.5in; }
  }
  html, body {
    margin: 0;
    font-family: Arial, sans-serif;
    font-size: 10pt;
  }
  h1.title {
    font-size: 2em;
    font-weight: normal;
    margin-bottom: 0.5em;
  }
  h2.subtitle {
    font-size: 1.2em;
    font-weight: normal;
    margin-top: -0.5em;
  }
  p.description {
    margin-bottom: 1.5em;
  }
  table {
    width: 98%;
    border-collapse: collapse;
  }
  th {
    text-align: left;
  }
  td {
    border: 1px solid #ccc;
    padding: .5em;
  }
  .noBorder {
    border: none !important;
    padding-top: 20px !important;
  }
  .boldIt {
    font-weight: bold;
  }
`;

export const letterHead: string = `
  @media print {
    @page { margin: 0.5in; }
  }
  table {
    width: 98%;
    border-collapse: collapse;
  }
  th {
    text-align: left;
  }

  .boldIt {
    font-weight: bold !important;
  }

  td {
    border: 1px solid #ccc;
    padding: .5em;
  }

  tfoot td {
    border: none;
  }

  table .text-xs-right {
    text-align: right;
  }
  
  .bar {
    width: 500%;
  }

  table.dateLine {
    margin-bottom: 3em;
  }
  
  html,
  body {
    margin: 0;
    font-family: Arial, sans-serif;
    font-size: 12pt;
  }
  
  .dateLine {
    border: 0;
    padding: 25px;
  }

  .dateLine td {
    padding: 0.1em;
  }

  .dateLine-header {
    font-size: 0.9em;
  }
  
  table {
    width: 98%;
    border-collapse: collapse;
  }
  th {
    text-align: left;
  }
  td {
    padding: 0.5em;
  }
  
  img {
    width: 10%;
  }
  
  .custNum {
    font-weight: normal;
  }
  
  .sONum {
    margin-left: 34px;
  }

  .deliveryCust {
    float: right;
    margin-top: 10px;
    font-weight: bold;
  }

  .lowerHalf {
    margin-top: -170px;
  }
  
  .topInv {
    line-height: 70%;
    height: 72px;
    width: 99%;
    display: inline-block;
  }
  
  .topInv2 {
    line-height: 70%;
    height: 142px;
    width: 99%;
    display: inline-block;
  }
  
  .topInv3 {
    line-height: 70%;
    margin-top: 1%;
    height: 90px;
    width: 99%;
    display: block;
  }
  
  .topInv4 {
    font-Size: 13px;
    line-height: 70%;
    margin-top: 1%;
    height: 300px;
    width: 99%;
    display: inline-block;
  }
  
  .middleInv {
    height: 72px;
    width: 100%;
    display: inline-block;
  }
  
  .compNameFarmCon {
    margin-top: 10px;
    display: inline-block;
    height: 100%;
    float: left;
  }

  .compNameFarmConBold {
    font-weight: bold;
    margin-top: 10px;
    display: inline-block;
    height: 100%;
    float: left;
  }
  
  .invSOCon {
    margin-top: 10px;
    display: inline-block;
    float: right;
    height: 100%;
  }
  
  .invSOCon2 {
    margin-top: 10px;
    display: inline-block;
    float: right;
    height: 100%;
    text-align: right;
  }
  
  .imgCon {
    display: inline-block;
  }
  
  .th {
    height: 70px;
    width: 89%;
    display: inline-block;
    float: right;
  }
  
  .po {
    margin-top: 10px;
    display: inline-block;
    height: 100%;
    float: left;
    margin-left: 11%;
  }

  .po2 {
    margin-top: -70px;
    display: inline-block;
    height: 100%;
    float: left;
    margin-left: 11%;
  }
  
  .totalUnits {
    display: inline-block;
    height: 40px;
    float: left;
    margin-left: 663px;
  }
  
  .totalNumber {
    display: inline-block;
    height: 40px;
    float: left;
    margin-left: 2%;
  }
  
  .totalDue {
    display: block;
    height: 40px;
    text-align: right;
  }
  
  .totalDueNumber {
    display: inline-block;
    height: 40px;
    float: left;
    margin-left: 2%;
  }
  
  .remit {
    display: block;
    height: 70%;
    width: 60%;
    float: left;
  }
  
  .questions {
    display: block;
    height: 70%;
    width: 40%;
    float: left;
  }
  
  .recText {
    
  }
  
  .temp {
    display: block;
    height: 30%;
    width: 40%;
    float: left;
  }
  
  .receivedBy {
    display: block;
    height: 30%;
    width: 60%;
    float: left;
  }
  
  .lineee {
    margin-left: 14px;
  }

  .noBorder {
    border: none !important;
  }
  
`;

export const deliverySlip: string = `
html,
body {
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 10pt;
}

table {
  width: 98%;
  border-collapse: collapse;
}
th {
  text-align: left;
}
td {
  padding: 0.5em;
}

img {
  width: 10%;
}

.topInv {
  line-height: 70%;
  height: 72px;
  width: 100%;
  display: inline-block;
}

.topInv2 {
  margin-top: 80px;
  line-height: 70%;
  height: 72px;
  width: 100%;
  display: inline-block;
}

.compNameFarmCon {
  margin-top: 10px;
  display: inline-block;
  height: 100%;
  float: left;
}

.invSOCon {
  margin-top: 10px;
  display: inline-block;
  float: right;
  height: 100%;
}

.invSOCon2 {
  margin-top: 10px;
  display: inline-block;
  float: right;
  height: 100%;
  text-align: right;
}

.imgCon {
  display: inline-block;
}

.th {
  height: 70px;
  width: 89%;
  display: inline-block;
  float: right;
}

.po {
  margin-top: 10px;
  display: inline-block;
  height: 100%;
  float: left;
  margin-left: 11%;
}

.poRefAndDate {
  display: flex;
  flex-flow: row wrap;
  flex: 1 0 auto;
}

.deliveryCust {
  font-size: 16px;
  float: right;
  margin-top: 10px;
  font-weight: bold;
}

.compNameFarmConBold {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  display: inline-block;
  height: 100%;
  float: left;
}

`;

export const purchaseOrder: string = `
html,
body {
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 10pt;
}

table {
  width: 98%;
  border-collapse: collapse;
}
th {
  text-align: left;
}
td {
  padding: 0.5em;
}

img {
  width: 10%;
}

.topInv {
  line-height: 70%;
  height: 72px;
  width: 100%;
  display: inline-block;
}

.topInv2 {
  line-height: 70%;
  height: 142px;
  width: 100%;
  display: inline-block;
}

.compNameFarmCon {
  margin-top: 10px;
  display: inline-block;
  height: 100%;
  float: left;
}

.invSOCon {
  margin-top: 10px;
  display: inline-block;
  float: right;
  height: 100%;
}

.invSOCon2 {
  margin-top: 10px;
  display: inline-block;
  float: right;
  height: 100%;
  text-align: right;
}

.imgCon {
  display: inline-block;
}

.th {
  height: 70px;
  width: 89%;
  display: inline-block;
  float: right;
}

.po {
  margin-top: 10px;
  display: inline-block;
  height: 100%;
  float: left;
  margin-left: 11%;
}

.poRefAndDate {
  display: flex;
  flex-flow: row wrap;
  flex: 1 0 auto;
}

.deliveryCust {
  float: right;
  margin-top: 10px;
  font-weight: bold;
}

.compNameFarmConBold {
  font-weight: bold;
  margin-top: 10px;
  display: inline-block;
  height: 100%;
  float: left;
}

.boldIt {
  font-weight: bold;
}
`;

export const Manifest: string = `
  @media print {
    @page { margin: 0.5in; }
  }
  html, body {
    margin: 0;
    font-family: Arial, sans-serif;
    font-size: 10pt;
  }
  h1.title {
    font-size: 2em;
    font-weight: normal;
    margin-bottom: 0.5em;
  }
  p.description {
    margin-bottom: 1.5em;
  }
  table {
    width: 98%;
    border-collapse: collapse;
  }
  th {
    text-align: left;
    font-size: 15px;
  }
  td {
    padding: .5em;
  }

  img {
    width: 10%;
  }

  .delivery {
    border-style: solid;
    border-color: black;
    border-width: 0 0 1px 0;
    width: 100%;
    padding: 5px;
    margin: 5px;
  }
  
  .deliveryBoxes {
    display: flex;
    flex-flow: row wrap;
    flex: 1 0 auto;
  }
  
  .deliveryBox {
    border-style: solid;
    border-width: 1px;
    border-color: black;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
  }
`;

export const stockMasterCaseLabel: string = `
  @media print {
    @page { margin: 0; }
  }

  html, body, #print-wrap {
    margin: 0;
    height: 100%;
  }

  .row {
    box-sizing: border-box;
    padding: 10vh 4vw;
    break-before: page;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    font-size: 3vw;
    font-family: 'Courier New', monospace;
  }

  .left-col, .right-col {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .left-col > *,
  .right-col > * {
    flex: 1;
    margin: 0;
  }

  .left-col {
    border-right: 1px solid #ccc;
    flex: 0 0 57%;
    max-width: 57%;
    margin-bottom: -100px;
  }

  .right-col {
    flex: 0 0 42%;
    max-width: 42%;
    text-align: right;
  }

  .product-name, .dates, .order-number {
    flex-grow: 0;
    flex-shrink: 1;
  }

  .order-number {
    font-size: 3em;
  }

  .line-name {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
  }

  .line-name-transplant {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
  }

  .line-name h2 {
    margin: 0;
    font-size: 3.3em;
    font-weight: 500;
  }

  .line-name-transplant h2 {
    margin: 0;
    font-size: 2em;
    font-weight: 500;
  }
 
  .line-name h4 {
    margin-top: 0.3em;
    margin-bottom: 0.8em;
  }

  .line-name-transplant h4 {
    margin-top: 0.3em;
    margin-bottom: 0.8em;
  }

  .dates {
    padding-top: 0.5em;
  }

  .line-name-prop {
    font-size: 2em;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
  }

  .prop-dates {
    font-weight: bold;
    font-size: 70px;
  }

  .dates p {
    margin: 0;
    font-size: 2em;
    text-transform: uppercase;
  }

  .product-name h2 {
    margin: 0;
  }

  .product-name h4 {
    margin-top: 0.3em;
    margin-bottom: 0.8em;
`;

export const trayLabel: string = `
  @media print {
    @page { margin: 0; }
  }
  html, body, #print-wrap, .v-card {
    margin: 0;
    height: 100%;
  }
  table {
    width: 100%;
  }
  .box {
    box-sizing: border-box;
    height: 100%;
    display: block;
    position: relative;
    font-size: 3.5vw;
    font-family: Arial, sans-serif;
  }
  .box p {
    margin: 0;
  }
  .box table {
    border-collapse: collapse;
  }
  .wrap {
    margin-top: 12vh;
    page-break-after: always;
  }
  .box .primary-name {
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    padding: 0 1vw;
  }
  .box .secondary-name {
    font-size: 0.9em;
    border: none;
    padding: 2px 1vw;
  }
  table td {
    text-align: center;
    border: 1px solid #ddd;
    padding: 2px 4px;
  }
`;

export const casingFlex: string = `
  @media print {
    @page { margin: 0; }
  }
  html, body, #CaseForm {
    margin: 0;
    height: 98%;
  }
  .loop-container {
    box-sizing: border-box;
    break-before: page;
    height: 68%;
    margin: 0;
  }
  .row {
    box-sizing: border-box;
    padding-left: 2%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    font-size: 2vw;
    font-family: 'Courier New', monospace;
  }
  .left-col, .middle-col, .right-col {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
  .left-col > *,
  .middle-col > *,
  .right-col > *,
  .line-name > * {
    flex: 1;
    margin: 0;
  }
  .left-col {
    flex: 0 0 55%;
    max-width: 55%;
    white-space:nowrap;
    order: 1;
  }
  .middle-col {
    flex: 0 0 28%;
    max-width: 28%;
    align-items:left;
    order: 2;
    position: relative;
    left: -70px;
  }
  .right-col {
    flex: 0 0 18%;
    max-width: 18%;
    align-items: center;
    justify-content: center;
    order: 3;
    margin-left: -25px;
  }
  .brand-img-logo {
    max-height: 100%;
    max-width: 100%;
    margin-bottom: -20px;
  }
  .item-data-row {
    padding-top:18%;
    margin-bottom: 200px;
  }
  .barcode-container {
    display: flex;
    justify-content: center;
    margin-left: -20px;
  }
  .item-data { 
    white-space:nowrap;
    font-size:1.3em;
    margin-top:auto;
    border: 1px solid black;
  }
  .item-data-line {
    margin:0;
  }
  .barcode-element {
    max-height: 10vh !important;
    max-width: 100% !important;
  }
  .barcode {
    max-height: 10vh !important;
    max-width: 100% !important;
  }
  svg {
    max-width:100%;
  }
  .qr-code svg {
    max-width: 100% !important;
  }
  .upc-logo {
    flex-grow: 0;
    flex-shrink: 1;
    flex: 0 0 20%;
    max-width: 20%;
    padding-top: 2%;
    text-align: center;
  }
  .barcodeDiv {
    flex-grow: 0;
    flex-shrink: 1;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;
    align-items: center;
    text-align: center;
  }
  .barcodeDiv .vue-barcode-element {
    max-height: 30vh;
    max-width: 88vw;
  }
  .barcodeDiv .barcode {
    max-height: 30vh;
    max-width: 88vw;
  }
  .bottomBarcode {
    padding: 0;
    margin: 0;
    width: 10%;
    align-items: right;
  }
  .barcodeHolder {
    text-align: center;
  }
  .barcodeHolder .vue-barcode-element {
    max-height: 20vh;
    max-width: 30vw;
  }
  .product-name, .dates, .order-number {
    flex-grow: 0;
    flex-shrink: 1;
  }
  .order-number {
    border: 2px solid black;
    text-align: center;
    align-items: center;
    font-size: 5em;
    letter-spacing: -0.03em;
    margin-left: -45px;
    line-height: 0.8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .order-number .date-text {
    font-size: 0.6em;
    margin-top: -0.1em;
    display: block;
  }
  .usa-line {
    font-weight: bold;
    font-size:1.2em;
    margin:0;
    margin-top:2%;
    margin-left:1%
  }
  .perish-line {
    margin:0; 
    font-weight: bold;
    margin:0;
    margin-left:1%;
  }
  .refrigerate-line {
    font-size: 1.6875em;
    margin:0;
    margin-left:1%
  }
  .no-margin {
    margin:0;
  }
  .adjust-to-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .line-name {
    display: flex;
    max-height: 10% !important;
    align-items: left;
    margin:1%;
    font-weight: bold;
  }
  .line-name h2 {
    margin: 0;
    font-weight: 500;
    letter-spacing: -0.1em;
  }
  .dates {
    padding-top: 0.5em;
  }
  .dates p {
    margin: 0;
    font-size: 2.3em;
    text-transform: uppercase;
  }
  .product-name h2 {
    font-family: 'Courier New', monospace;
    font-size: 8vh;
    margin: 0;
    margin-left: 3%;
  }
  .product-name h4 {
    margin-top: 0.3em;
    margin-bottom: 0.8em;
  }
`;
